import React from 'react'
import { isEmpty } from 'ramda'
import {
  FirstStepSchema,
  SecondStepSchema,
  ThirdStepSchema,
  FourthStepSchema,
  FifthStepSchema
} from 'schemas/createJobOffer'

import JobOfferInitValues from 'models/createJobOffer'
import HandleDaysShifts from 'seniors-first-commons/services/handleDayShifts'
import CreateJobOfferWizard from 'components/organisms/CreateJobOfferWizard'
import JobOfferSummary from 'components/molecules/JobOfferSummary'
import { FirstStep } from './steps'
import {
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep
} from '../CreateJobOfferLayout/steps'
import { CREATE_PUBLIC_JOB_OFFER_STEPS } from 'seniors-first-commons/utils/constants'
import classes from '../CreateJobOfferLayout/index.module.css'
import { initialAddresses } from 'root/constants'

const WizardStep = ({ children, ...props }) => children(props)

const CreatePublicJobOfferLayout = ({
  logicPropsAndMethods: {
    matchesMobile,
    user,
    profile,
    chosenCountry,
    loading,
    onFormSubmit
  }
}) => {

  const { address_lat, address_lng, phone } = initialAddresses[chosenCountry()]

  return (
    <div className={`container section full-height ${matchesMobile ? 'has-padding-lr-7 is-margineless-lr' : ''}`}>
      <div className='part'>
        <CreateJobOfferWizard
          hasGoBackButton={true}
          initialValues={new JobOfferInitValues({
            address_lat,
            address_lng,
            phone
          })}
          loadingSubmit={loading}
          matchesMobile={matchesMobile}
          onSubmit={onFormSubmit}
          stepsLineStyles={'full-width'}
          styles={classes.componentsWrapper}
          wizardSteps={CREATE_PUBLIC_JOB_OFFER_STEPS}
        >
          <WizardStep validationSchema={FirstStepSchema}>
            {({ parentState: { ...formik }}) => (
              <FirstStep
                country={chosenCountry()}
                user={user}
                {...formik}
              />
            )}
          </WizardStep>

          <WizardStep validationSchema={SecondStepSchema}>
            {({parentState: { ...formik }}) => (
              <SecondStep {...formik} />
            )}
          </WizardStep>

          <WizardStep validationSchema={ThirdStepSchema}>
            {({parentState: { ...formik }}) => (
              <ThirdStep
                matchesMobile={matchesMobile}
                {...formik}
              />
            )}
          </WizardStep>

          <WizardStep validationSchema={FourthStepSchema}>
            {({parentState: { ...formik }}) => (
              <FourthStep {...formik} />
            )}
          </WizardStep>

          <WizardStep validationSchema={FifthStepSchema}>
            {({parentState: { ...formik }}) => (
              <FifthStep profile={profile} {...formik} />
            )}
          </WizardStep>

          <WizardStep>
            {({
              parentState: { values: {
                visiting_care_day_and_hours,
                live_in_care,
                ...rest
              }}}) => {
              let data = {}

              if (rest.hiring_modality === 'visiting_care'){
                const formattedVisitingCareTimes = visiting_care_day_and_hours
                  .map(({ day_of_week, hour_from, hour_to }) => ({
                    day_of_week: day_of_week.value,
                    hour_from: hour_from.value,
                    hour_to: hour_to.value
                  }))
                data = { visiting_care_day_and_hours: formattedVisitingCareTimes, ...rest }

                HandleDaysShifts.handleVisitingCareTimes(data)
              }
              else if (rest.hiring_modality === 'live_in_care') {
                data = {
                  live_in_care_day_of_week_from: live_in_care.day_of_week_from.value,
                  live_in_care_day_of_week_to: live_in_care.day_of_week_to.value,
                  live_in_care_hour_from: live_in_care.hour_from.value,
                  live_in_care_hour_to: live_in_care.hour_to.value,
                  ...rest
                }
                HandleDaysShifts.handleLiveInCareTimes(data)
              }

              return (
                <JobOfferSummary
                  currency={profile?.currency}
                  matchesMobile={matchesMobile}
                  offerValues={isEmpty(data) ? new JobOfferInitValues() : data }
                  withName={false}
                />
              )}}
          </WizardStep>
        </CreateJobOfferWizard>
      </div>
    </div>
  )
}

export default CreatePublicJobOfferLayout
