import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import WizardSteps from 'seniors-first-commons/components/atoms/Steps'
import { ArrowLeftIcon } from 'seniors-first-commons/components/atoms/images/icons'


const CreateJobOfferWizard = ({
  matchesMobile,
  children,
  initialValues,
  wizardSteps,
  hasGoBackButton=false,
  loadingSubmit=false,
  onSubmit,
  ...props
}) => {
  const [stepNumber, setStepNumber] = useState(0)
  const steps = React.Children.toArray(children)
  const [snapshot, setSnapshot] = useState(initialValues)

  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1

  const next = values => {
    setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
  }

  const onStepChange = step => {
    // setSnapshot(values)
    if(step < stepNumber) {
      setStepNumber(step)
    }
  }

  const onGoBack = () => {
    setStepNumber(Math.min(stepNumber - 1, totalSteps - 1))
  }

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag)
    }
    if (isLastStep) {
      return onSubmit(values, bag)
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  return (
    <>
      {hasGoBackButton && stepNumber >= 1 &&
        <button
          className={`button-text is-flex is-align-items-center ${!matchesMobile ? 'has-margin-l-5' : 'is-marginless-lr is-paddingless-lr'}`}
          onClick={onGoBack}
          style={{zIndex: '9'}}
        >
          <ArrowLeftIcon className='button-text-icon' height='24px' width='24px' />
          <FormattedMessageWithClass
            className='has-text-weight-light'
            id='client.createOffer.back'
          />
        </button>
      }
      <p className='t1-bold has-text-centered primary'>
        <FormattedMessageWithClass id='client.createOffer.title' />
      </p>

      <WizardSteps
        additionalClasses={props.stepsLineStyles}
        currentStep={stepNumber}
        matchesMobile={matchesMobile}
        onChange={!hasGoBackButton ? onStepChange : () => {}}
        steps={wizardSteps}
      />

      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {formik => (
          <Form className={props.styles}>
            {React.cloneElement(step, { parentState: { ...formik } })}

            <div className='has-text-centered has-margin-t-1 full-width'>
              <button
                className={`button filled ${matchesMobile ? 'full-width' : ''} ${loadingSubmit ? 'is-loading' : ''}`}
                disabled={loadingSubmit}
                style={{ height: '58px', width: '180px' }}
                type='submit'
              >
                <FormattedMessageWithClass
                  id={isLastStep
                    ? 'client.createOffer.createOffer'
                    : 'client.createOffer.continue'
                  }
                />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CreateJobOfferWizard
